
































import {
  defineComponent, computed, PropType,
} from '@vue/composition-api';
import { FiveStars } from '@fc/angie-ui';
import CustomerIcon from '~/components/CustomerIcon.vue';
import { IStoryblokMedia } from '~/selectors/selectors';
import { ICustomerIconImage } from './types/CustomerIcon';
import ReviewCustomerData from './ReviewCustomerData.vue';

export interface IReviewProps {
    codeId: string;
    name: string;
    title: string;
    dateJoined: string;
    icon: ICustomerIconImage;
    stars: string;
    body: object;
    date: string;
    images?: IStoryblokMedia[];
}

export default defineComponent({
  name: 'Review',
  components: {
    CustomerIcon,
    FiveStars,
    ReviewCustomerData,
  },
  props: {
    reviewData: {
      type: Object as PropType<IReviewProps>,
      required: true,
    },
  },
  setup(props) {
    const joinedText = computed(() => {
      const date = new Date(props.reviewData.dateJoined);
      const month = date.toLocaleString('default', { month: 'long' }).slice(0, 3);
      return `Joined ${month} ${date.getFullYear()}`;
    });

    return {
      formattedDate: computed(() => new Date(props.reviewData.date?.replace(/-/g, '/')).toLocaleDateString('en-US')),
      numberOfStars: computed(() => Number(props.reviewData.stars)),
      joinedText,
    };
  },
});
