var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"customer-icon",class:{'customer-icon__icon--border': _vm.border}},[_c('div',{staticClass:"customer-icon__icon"},[_c('FCImage',{attrs:{"fc-provider":"storyblok","fc-modifiers":{
        isStatic: true,
      },"nuxt-image-props":{
        src: _vm.validImage.filename,
        alt: _vm.validImage.alt,
        width: '100',
        height: '100',
        sizes: 'xs:100px',
      }}})],1),_vm._v(" "),(_vm.isVerified)?_c('div',{staticClass:"customer-icon__check-mark fc-flex fc-align-center fc-justify-center"},[_c('FontAwesomeIcon',{attrs:{"icon":['fc', 'check-mark']}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }