import { ref, computed } from '@vue/composition-api';
import { chunk } from 'lodash';

interface IPaginationOptions {
  perPage: number;
}

export function usePagination(data: any[], options: IPaginationOptions) {
  const { perPage } = options;

  const currentPage = ref(1);
  const disableNext = ref(false);
  const disablePrev = ref(true);

  const pageData = computed(() => chunk(data, perPage));
  const currentPageData = computed(() => pageData.value[currentPage.value - 1]);
  const totalPages = computed(() => Math.ceil(data.length / perPage) || 0);

  function getNextPage() {
    currentPage.value += 1;

    updatePageButtons();
  }

  function getPreviousPage() {
    currentPage.value -= 1;

    updatePageButtons();
  }

  const pageNumbers = computed(() => {
    const shownPages = 3;
    const result = [];

    if (currentPage.value > totalPages.value - shownPages && currentPage.value !== 1) {
      result.push(1, '...', totalPages.value - 2, totalPages.value - 1, totalPages.value);
    } else {
      result.push(currentPage.value, currentPage.value + 1, currentPage.value + 2, '...', totalPages.value);
    }

    return result;
  });

  function updatePageButtons() {
    if (currentPage.value !== totalPages.value) { disableNext.value = false; }
    if (currentPage.value === totalPages.value) {
      disableNext.value = true;
      disablePrev.value = false;
    }
    if (currentPage.value === 1) disablePrev.value = true;
    if (currentPage.value !== 1) disablePrev.value = false;
  }

  function getPageClicked(page: string) {
    currentPage.value = Number(page);

    updatePageButtons();
  }

  return {
    currentPage,
    getNextPage,
    getPreviousPage,
    pageNumbers,
    currentPageData,
    disableNext,
    disablePrev,
    getPageClicked,
  };
}
